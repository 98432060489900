"use client";

import { ApolloLink, HttpLink } from "@apollo/client";
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";

import { useNextPublicEnvContext } from "@/src/app/[locale]/next-public-provider";
import { getAuthLink } from "@/src/app/_lib/get-auth-link";
import { WithChildren } from "@/src/types/generic-react-props";

function makeClient(
  sessionTokenValue: string | undefined,
  sudoFnr: string | undefined,
  appBaseUrl: string,
) {
  const authLink = getAuthLink(sessionTokenValue, sudoFnr);
  const httpLink = new HttpLink({
    uri: `${appBaseUrl}/api/graphql`,
  });

  const httpLinkWithAuth = authLink ? authLink.concat(httpLink) : httpLink;

  return new ApolloClient({
    cache: new InMemoryCache(),
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLinkWithAuth,
          ])
        : httpLinkWithAuth,
    credentials: "same-origin",
  });
}

interface ApolloWrapperProps extends WithChildren {
  sessionTokenValue: string | undefined;
  sudoFnr: string | undefined;
}

export function ApolloWrapper({
  children,
  sessionTokenValue,
  sudoFnr,
}: ApolloWrapperProps) {
  const { appBaseUrl } = useNextPublicEnvContext();

  return (
    <ApolloNextAppProvider
      makeClient={() =>
        makeClient(
          sessionTokenValue,
          sudoFnr,
          appBaseUrl ?? "NO_APP_BASE_URL_PROVIDED",
        )
      }
    >
      {children}
    </ApolloNextAppProvider>
  );
}

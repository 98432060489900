"use client";

import { useTranslations } from "next-intl";

import { Select } from "@sikt/sds-select";

import { getFragmentData } from "@/src/__generated__";
import { PersonInfoFragment } from "@/src/app/[locale]/_queries/person-info-fragment";
import { FakeUserInfo } from "@/src/app/[locale]/_queries/types";
import { useFakeUserContext } from "@/src/app/_lib/test-users/fake-user-context";

import BorderSection from "./border-section";
import css from "./menu.module.css";

const getUserFromPersonId = (
  fakeUserList: FakeUserInfo[],
  id: string,
): FakeUserInfo | undefined =>
  fakeUserList.find(
    (user) => getFragmentData(PersonInfoFragment, user).id === id,
  );

interface FakeUserSectionProps {
  fakeUserList: FakeUserInfo[];
}

const FakeUserSection = ({ fakeUserList }: FakeUserSectionProps) => {
  const t = useTranslations("header.menu.fake-user");
  const { fakeUserInfo, setFakeUserInfo } = useFakeUserContext();
  const fakeUserInfoData = getFragmentData(PersonInfoFragment, fakeUserInfo);

  const fakeUserOptions = [
    { label: t("no-user-selected"), value: "" },
    ...fakeUserList.map((user) => {
      return {
        label: `${user.fornavn} ${user.etternavn}`,
        value: getFragmentData(PersonInfoFragment, user).id,
      };
    }),
  ];

  return (
    <BorderSection contentDirection="column">
      <Select
        className={css.fakeUserSelect}
        label={t("test-user")}
        options={fakeUserOptions}
        value={fakeUserInfoData?.id ?? ""}
        onChange={({ target: { value } }) => {
          setFakeUserInfo(getUserFromPersonId(fakeUserList, value));
        }}
        helpText={t("help-text")}
      />
    </BorderSection>
  );
};

export default FakeUserSection;
